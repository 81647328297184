.footer {
    background-color: rgb(17,30,43);
    color: white;
    padding: 2rem 0;
    font-family: 'Roboto Condensed', sans-serif;
    position: relative;
    margin-top: 50px;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
}

.contact-info {
    flex: 0 0 auto;
    text-align: left;
}

.contact-info h3 {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    font-weight: 700;
}

.footer a {
    color: white;
    text-decoration: none;
    transition: color 0.2s;
    display: block;
    margin-bottom: 0.25rem;
}

.footer a:hover {
    color: #cccccc;
}

.footer-logo-section {
    display: flex;
    align-items: center;
    flex: 1;
}

.footer-logo-image {
    width: 60px;
    height: auto;
    margin-right: 1rem;
}

.footer-text p {
    margin: 0;
    line-height: 1.5;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-logo-section {
        margin-bottom: 1.5rem;
        justify-content: center;
    }

    .contact-info {
        text-align: center;
    }
}