.loginForm{
    width: 45vh;
    height: auto;
    
}
.login-error{
    /* display: flex; */
    text-align: center;
    align-items: center;
    color: red;
    margin-top: 12px;
    /* margin-left: 70px; */
    font-family:Arial, Helvetica, sans-serif;
    font-size: 18px;

}
.popup-content-login{
    background-color: #d9d9d9;
    padding: 20px;
    border-radius: 10px;
    width: auto;
    max-width: 615px;
    height: auto;
    max-height: 100vh;
    box-sizing: border-box;
}
.loginForm h2{
    font-size: 30px;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
}

.loginForm .input-box{
    width: 100%;
    height: 50px;
    margin: 30px 0;
    position: relative;
    
}
.loginForm .input-box input:focus{
    border: 1px solid #9ec7f3;
    box-shadow: 0 0 5px #9ec7f3;
    outline: none;

}
.input-box input[type = "text"]{
    width: 100%;
    height: 100%;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 10px;
    padding: 20px 45px 20px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-image: url(../../../../assets/Email_login.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 22.5px;
    
}
.loginForm .input-box .toggle-password {
    position: absolute;
    right: 10px; 
    top: 50%; 
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;

}
.loginForm .input-box .toggle-password img {
    height: 20px;
    width: auto;
}
.input-box input[type = "Viewpassword"]{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 10px;
    padding: 20px 45px 20px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


.input-box input[type = "password"]{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 10px;
    padding: 20px 45px 20px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    
}
.input-box input[type = "password"] > i{
    position: absolute;
    background-image: url(../../../../assets/EyeIcon.png);
    right: 20px;
}


.forgotpasswd > label > a{
    text-decoration: none;
    font-size: 13px;
    display: block;
    text-align: center;
   /* margin-left: 100px;*/

}
.forgotpasswd a:hover{
    text-decoration: underline;
}

.loginForm .btn{
    width: 75%;
    height: 30.5px;
    border: none;
    outline: none;
    background-color: rgb(17, 30, 43);
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    color: whitesmoke;
   display: block;
   margin: auto;
   
}
.loginForm .btn:hover{
    background-color: rgb(20, 47, 74);
}
.btn:active {
    transform: scale(0.95); 
  }

