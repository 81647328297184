/* MainPhoto Styles */
.fullscreen-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.scrolled-background {
    background: white;
    margin-top: 95vh;
    position: relative;
}

.closeButton {
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
}

.change-background-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 10;
}

.change-background-button:hover {
    background-color: #45a049;
}

.preview-buttons {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
    z-index: 20;
}

.preview-buttons button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.preview-buttons button:hover {
    background-color: #45a049;
}

.preview-buttons button:last-child {
    background-color: #f44336;
}

.preview-buttons button:last-child:hover {
    background-color: #d32f2f;
}

/* BackgroundImageManager Styles */
.background-image-manager {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 800px;
    height: 80%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    overflow-y: auto;
    z-index: 1000;
}

.add-image-button {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    gap: 5px;
}

.add-image-button:hover {
    background-color: #45a049;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    margin-top: 70px;
}

.image-item {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.3s;
}

.image-item:hover {
    transform: scale(1.05);
}

.image-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.image-item button {
    position: absolute;
    bottom: 5px;
    background-color: rgba(0,0,0,0.7);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.8em;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    gap: 5px;
}

.image-item button:hover {
    background-color: rgba(0,0,0,0.9);
}

.image-item button:first-of-type {
    left: 5px;
}

.image-item button:last-of-type {
    right: 5px;
}

.upload-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.upload-modal {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    width: 90%;
    max-width: 400px;
}

.upload-modal h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
}

.file-input-wrapper {
    position: relative;
    margin-bottom: 20px;
}

.file-input-wrapper input[type="file"] {
    position: absolute;
    left: -9999px;
}

.file-input-label {
    display: inline-block;
    padding: 10px 15px;
    background-color: #f0f0f0;
    color: #333;
    border: 2px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.file-input-label:hover {
    background-color: #e0e0e0;
}

.upload-modal-buttons {
    display: flex;
    justify-content: space-between;
}

.upload-modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    gap: 5px;

}

.upload-button {
    background-color: #4CAF50;
    color: white;
}

.upload-button:hover {
    background-color: #45a049;
}

.upload-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.close-button {
    background-color: #f44336;
    color: white;
}

.close-button:hover {
    background-color: #d32f2f;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .background-image-manager {
        width: 95%;
        height: 90%;
    }

    .image-grid {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }

    .upload-modal {
        width: 95%;
    }
}

@media (max-width: 480px) {
    .image-grid {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }

    .upload-modal-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .upload-modal-buttons button {
        width: 100%;
    }
}

