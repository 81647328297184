.important-event-elements {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.important-event-elements-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(21, 33, 61, 1);
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}
.important-event-elements-header h2 {
    margin: 0;
    font-size: 1.25em;
    font-weight: 500;
}

.important-event-elements-header svg {
    font-size: 24px;
}

.important-event-elements-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    padding-top: 20px;
}

.event-element-wrapper {
    width: 100%;
}

.event-element-wrapper.wide {
    grid-column: span 2;
}


.important-event-elements .eventElementContainer {
    background-color: rgba(21, 33, 61, 1);
    color: #fff;
    height: 360px;
    display: flex;
    border-radius: 5px;
    position: relative;
}

.informationType {
    background-color: #fdc32e !important;
    height: 360px;
    display: flex;
    border-radius: 5px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .important-event-elements-header h2 {
        font-size: 1em;
    }
    .important-event-elements-list {
        grid-template-columns: 1fr;
    }
    .event-element-wrapper.wide {
        grid-column: span 1;
    }
}


.eventElementContainer > .importantButton.active {
    background-color: #FFD700;
}

.eventElementContainer > .importantButton.active svg {
    color: #FFFFFF;
}