.modalBackground {
	position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
}

.smallModalBackground {
    font-family: 'Roboto condensed' , sans-serif;
    font-weight: 500;
    color: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 200px;
    top: 470px;
    left: 40px;
    margin: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
}

@media screen and (max-width: 800px) {
    .smallModalBackground {
        display: none;
    }
}

@media screen and (min-width: 800px) {        
    .popupEventElementContainer{min-width: 750px;
        min-height: 200px;
        max-width: 750px;
    }
}
