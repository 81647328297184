@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Montserrat:wght@300;400;700&family=Open+Sans:wght@300;400;700&family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Open+Sans:wght@400;700&family=Lato:wght@400;700&display=swap');

.navbar.disabled {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
}

body.popup-open {
    overflow: hidden;
}

.main {
    font-family: 'Poppins', sans-serif;
    width: 60%;
    margin: auto;
    background-color: #FFFFFF;
}

@media screen and (max-width: 1000px) {
    .main {
        width: 100%;
    }
    .textItems {
        font-size: 16px;
    }
}

@media screen and (max-width: 800px) {
    .main {
        width: 100%;
    }
    .textItems {
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .main {
        width: 100%;
    }
    .textItems {
        font-size: 12px;
    }
}

@media screen and (max-width: 800px) {
    .headerContainer > h2 {text-align: center;
    margin:20px;
    }
    .description{margin-left: 10px;
    margin-right: 10px;}

  
}

.content {
    border-radius: 6px;
    margin-bottom: 20px;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 6px solid rgb(165, 133, 83);
    padding-top: 10px;
    margin-bottom: 20px;
}

.headerContainer > h2 {
    color: #111E2B;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    flex-grow: 1;
    padding:10px;
}

.actionButton {
    color: white;
    font-family: 'Poppins', sans-serif;
    background-color: #4CAF50;
    padding: 12px 30px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    font-size: 1.25em;
    font-weight: 500;
    justify-content: flex-end;
}

.content > div > p {
    color: #333;
    font-size: 1.2em;
    margin: 0;
}

.info {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 2px solid rgba(224, 224, 224, 1);
    border-bottom: 2px solid rgba(224, 224, 224, 1);
}

.numberPeople {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(224, 224, 224, 1);
    text-align: center;
    display: flex;
}

.textContainer {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
}

.textItems {
    text-align: center;
    margin-bottom: 10px;
    width: 33%;
}

.textItemsPeople {
    text-align: center;
    margin-bottom: 10px;
    width: 33%;
}

.description {
    margin-top: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    white-space: pre-wrap;
}

.emailSearch {
    display: flex;
    width: 60%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.emailSearch > div > label {
    font-size: 15px;
    margin: 9px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.inputEmial input[type = "text"] {
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 10px;
    padding: 10px 50px 10px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-image: url(../../assets/Email_login.png);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    font-size: 16px;
    width: 400px;
}

.emailSearch > div > button {
    border-color: rgba(21, 33, 61, 1);
    background-color: rgba(21, 33, 61, 1);
    border-radius: 10px;
}

.emailSearch > div > button:active {
    box-shadow: 0px 0px 10px rgba(0, 255, 0, 1);
}

.checkBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.checkBox input {
    height: 20px;
    width: 20px;
}

@media (max-width: 768px) {
    .emailSearch {
        flex-wrap: wrap;
        gap: 10px;
    }

    .emailSearch > div > label {
        font-size: 10px;
        align-items: center;

    }

    .inputEmial {
        width: 100%; /* Make search bar take full width */
    }

    .inputEmial input[type="text"] {
        width: 60vw;
        align-items: center;
    }

    /* Make checkbox and search button stay on same line */
    .checkBox,
    .emailSearch > div:last-child {
        margin-top: 10px;
    }

    /* This ensures checkbox and search stay on same line */
    .checkBox {
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }
}

.warning-popup {
    font-family: 'Poppins', sans-serif;
    background-color: white;
    border: 2px solid #ff0000;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
}

.warning-popup p {
    color: #ff0000;
    font-weight: bold;
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
}

.warning-popup button {
    font-family: 'Poppins', sans-serif;
    background-color: #ff0000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
/* Existing styles... */

/* Font classes */
.ql-font-sans-serif {
    font-family: 'Arial', 'Helvetica', sans-serif;
}
.ql-font-serif {
    font-family: 'Georgia', 'Times New Roman', serif;
}
.ql-font-monospace {
    font-family: 'Monaco', 'Courier New', monospace;
}
.ql-font-roboto-condensed {
    font-family: 'Roboto Condensed', sans-serif;
}
.ql-font-times-new-roman {
    font-family: 'Times New Roman', Times, serif;
}
.ql-font-open-sans {
    font-family: 'Open Sans', sans-serif;
}
.ql-font-lato {
    font-family: 'Lato', sans-serif;
}

/* Font picker styles */
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
    content: 'Sans Serif';
    font-family: 'Arial', 'Helvetica', sans-serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
    content: 'Serif';
    font-family: 'Georgia', 'Times New Roman', serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
    content: 'Monospace';
    font-family: 'Monaco', 'Courier New', monospace;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto-condensed"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto-condensed"]::before {
    content: 'Roboto Condensed';
    font-family: 'Roboto Condensed', sans-serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
    content: 'Times New Roman';
    font-family: 'Times New Roman', Times, serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="open-sans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="open-sans"]::before {
    content: 'Open Sans';
    font-family: 'Open Sans', sans-serif;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lato"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lato"]::before {
    content: 'Lato';
    font-family: 'Lato', sans-serif;
}
.notification-message {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 10px 0;
    text-align: center;
    animation: fadeIn 0.3s ease-in;
}

.popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90vw;
}

.popup-container h3 {
    margin-top: 0;
    margin-bottom: 15px;
}

.popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 10px;
}

.popup-buttons .actionButton {
    flex: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
/* Add to your existing CSS file */

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 6px solid rgb(165, 133, 83);
    padding: 10px 20px;
    margin-bottom: 20px;
}

.headerContainer > h2 {
    color: #111E2B;
    margin: 0;
    flex-grow: 1;
}

.actionButton {
    color: white;
    font-family: 'Poppins', sans-serif;
    background-color: #4CAF50;
    padding: 12px 30px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    font-size: 1.25em;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

.actionButton:hover {
    background-color: #45a049;
}

.actionButton:active {
    background-color: #3d8b40;
}

@media screen and (max-width: 800px) {
    .headerContainer {
        flex-direction: column;
        gap: 10px;
        text-align: center;
    }

    .headerContainer > h2 {
        margin-bottom: 10px;
    }

    .actionButton {
        width: 100%;
        max-width: 300px;
    }
}

.button-group {
    display: flex;
    gap: 10px;
    align-items: center;
}

.actionButton.show-all {
    background-color: #4CAF50;
    transition: background-color 0.3s ease;
}

.actionButton.show-all:hover {
    background-color: #45a049;
}

.actionButton.hide-all {
    background-color: #ffd700;
    color: #000;
    transition: background-color 0.3s ease;
}

.actionButton.hide-all:hover {
    background-color: #ffcd00;
}

@media screen and (max-width: 800px) {
    .button-group {
        width: 100%;
        flex-direction: column;
    }

    .button-group .actionButton {
        width: 100%;
        margin: 5px 0;
    }
}
.departmentName {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.faculty-spots {
    color: #fff;
    margin-top: auto;
}