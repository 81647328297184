* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.navbar-mobile-container{
  display: flex;
}
@media only screen and (min-width: 991px) {
  .Navbar {
    height: 120px !important;
  }
  .navbar {
    padding: 0px 60px 10px;
  }
  .sprawdz-zapisy {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 990px) {
  .Navbar {
    height: 110px !important;
  }
  .navbar {
    padding: 0px 0px 0px;
  }
}

@media only screen and (min-width: 1201px) {
  .navbar nav {
    max-width: 1620px;
    display: block;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 400px) {
  .sprawdz-zapisy {
    white-space: unset;
    font-size: 16px !important;
  }
  .navbar .right {
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
  .navbar {
    padding: 0px 10px !important;
  }
  .sprawdz-zapisy {
    margin-left: 0px !important;
  }
  .logo {
    height: 60px !important;
  }
  .div930213 {
    height: 60px !important;
  }
}

@media only screen and (min-width: 500px) {
  .sprawdz-zapisy {
    white-space: nowrap !important;
  }
}

@media only screen and (max-width: 800px) {
  .text {
    display: none;
  }
  .navbar {
    padding: 0px 8px 0px;
  }
}

@media only screen and (min-width: 801px) {
  .textMobile {
    display: none;
  }
  .sprawdz-zapisy {
    white-space: unset;
  }
}

.logo {
  height: 90px !important;
}

.Navbar {
  position: sticky;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1500;
  font-size: 22px;
}

.div930213 {
  height: 90px !important;
}

.navbar.scrolled {
  background-color: white;
  transition: background-color 0.5s;
  color: #111E2B;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.navbar.scrolled a {
  color: rgba(133, 142, 153, 1);
}

.navbar.notscrolled {
  background-color: transparent;
  transition: background-color 0.5s;
}

.navbar.right {
  margin-left: auto;
}

.Navbar a {
  font-weight: 500;
  font-family: 'Roboto condensed', sans-serif;
  display: block;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
  color: white;
  font-size: 30px;
}

.Navbar .text {
  transform: translateY(-2px);
  white-space: nowrap;
}

.navbar .sprawdz-zapisy {
  transform: translateY(+2px);
}

.navbar .buttonlog {
  font-size: 21px !important;
  transform: translateY(+2px);
}

.sprawdz-zapisy {
  font-size: 21px !important;
}

.Navbar nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 110px;
  padding-left: 0px;
}

.navbar.notscrolled .buttonlog {
  background-color: transparent;
  font-size: 30px;
  border: none;
  color: white;
  font-family: 'Roboto Condensed', sans-serif;
}

.navbar.notscrolled .buttonlog:hover {
  color: wheat;
}

.navbar.scrolled .buttonlog {
  background-color: transparent;
  font-size: 30px;
  border: none;
  color: rgba(133, 142, 153, 1);
  font-family: 'Roboto Condensed', sans-serif;
}

.navbar.scrolled .buttonlog:hover {
  color: rgb(23, 22, 22);
}

.navbar .sprawdz-zapisy {
  margin-left: 20px;
}

.navbar .sprawdz-zapisy:hover {
  color: wheat;
}

.navbar .navbar.right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

/* Additional mobile fixes */
@media only screen and (max-width: 400px) {
  .navbar nav {
    min-height: 80px;
    height: auto;
    padding: 10px 0;
  }

  .navbar .right {
    margin: 5px 0;
  }

  .navbar .sprawdz-zapisy {
    font-size: 16px !important;
    margin-left: 20px;
    text-align: center;
  }

  .navbar.scrolled .buttonlog,
  .navbar.notscrolled .buttonlog {
    font-size: 16px !important;
  }

}

/* Tablet adjustments */
@media only screen and (min-width: 401px) and (max-width: 990px) {
  .navbar .right {
    padding-right: 10px;
  }

  .navbar .sprawdz-zapisy {
    margin-left: 20px;
  }
}
.navbar .right span {
  margin-right: 20px;
}