/* General Styles */
body, button, input {
    font-family: 'Poppins', sans-serif;
}

.main {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #FFFFFF;
    padding: 20px;
}

/* Layout Styles */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 6px solid #15213D;
    padding-bottom: 5px;
}

.temp-users-management{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 6px solid #15213D;
    padding-bottom: 5px;
}
.content-wrapper {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.events-section {
    flex: 3;
}

.calendar-section {
    flex: 2;
    max-width: 40%;
    margin-top: 60px;
}

/* Header Styles */
.section-title {
    font-size: 1.5em;
    color: #15213D;
    margin: 20px 0 0;
}

.temp-user-title {
    font-size: 1.5em;
    color: #15213D;
    margin: 20px 0 0;
}

.temp-users-button{
    background-color: #4CAF50;
    color: white;
    gap: 5px;
}


/* Button Styles */
.add-event-button, .sort-button, .show-all-button, .month-nav-btn,.temp-users-button,
input[type="date"], .pagination button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 40px;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-event-button {
    background-color: #4CAF50;
    color: white;
    gap: 5px;
}

.sort-button, .show-all-button, .month-nav-btn, .pagination button {
    background-color: #15213D;
    color: white;
}

.add-event-button:hover { background-color: #45a049; }
.sort-button:hover, .show-all-button:hover, .month-nav-btn:hover, .pagination button:hover {
    background-color: #1E2E4D;
}

/* Filter Styles */
.filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: space-between;
}

.filters > * {
    flex: 1;
}

/* Date Picker Styles */
input[type="date"] {
    border: 1px solid #15213D;
    color: #15213D;
    background-color: white;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(15%) sepia(32%) saturate(1032%) hue-rotate(187deg) brightness(95%) contrast(93%);
}

/* Calendar Styles */
.calendar {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.calendar-header h2 {
    margin: 0;
    font-size: 1.2em;
    text-transform: capitalize;
    color: #15213D;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
}

.calendar-day {
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 25%;
    font-size: 0.9em;
    cursor: pointer;
    padding: 5px;
    position: relative;
    overflow: hidden;
}

.calendar-day:hover { background-color: #e0e0e0; }
.calendar-day.outside-month { color: #999; background-color: #f8f8f8; }
.calendar-day.selected { background-color: #15213D; color: white; }

.date-number {
    font-weight: bold;
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 1em;
}

.event-dots {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2px;
    width: 70%;
    height: 40%;
    position: absolute;
    bottom: 5px;
    left: 15%;
    right: 15%;
}

.event-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: auto;
}

.event-dot-1 { background-color: #FF5722; }
.event-dot-2 { background-color: #FFC107; }
.event-dot-3 { background-color: #4CAF50; }
.event-dot-4 { background-color: #2196F3; }
.event-dot-5 { background-color: #9C27B0; }
.event-dot-6 { background-color: #E91E63; }

/* Event List Styles */
.event-item {
    background-color: #15213D;
    color: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    transition: all 0.3s ease;
    position: relative;
    padding: 20px;
}

.event-content {
    display: block;
    color: #fff;
    text-decoration: none;
}

.event-item:hover,
.event-item:hover {
    background-color: #1a2b4f;
    transition: transform 0.3s ease;
    transform: scale(1.02);
}

.event-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.event-header h3 {
    margin: 0;
    font-size: 1.2em;
    max-width: 70%;
    word-wrap: break-word;
}

.event-datetime {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.9em;
    min-width: 120px;
    padding-left: 10px;
    border-left: 2px solid #ffffff;
}

.event-datetime > div {
    font-weight: bold;
    text-align: left;
    width: 100%;
}

.event-content > p {
    margin: 0;
    font-size: 0.9em;
    color: rgba(255, 255, 255, 0.7);
    padding-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
}

/* Admin Actions Styles */
.admin-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: flex-end;
    padding-right: 10px;
}

.action-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    font-size: 1em;
    color: #fff;
    transition: background-color 0.3s;
}

.edit-button { background-color: #4CAF50; }
.copy-button {background-color: rgb(165,133,83)}
.visibility-button { background-color: #2196F3; }
.info-button { background-color: #FFC107; }

/* Registration Data Styles */
.registration-data {
    margin-top: 10px;
    padding: 10px 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.registration-data p {
    margin: 5px 0;
    font-weight: bold;
    flex: 1 1 auto;
    text-align: center;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}

.pagination button {
    background-color: #15213D;
    color: #fff;
    transition: background-color 0.3s, transform 0.2s;
}

.pagination button:hover {
    background-color: #1E2E4D;
    transform: translateY(-2px);
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
}

.pagination span {
    font-size: 0.9em;
    color: #15213D;
}

/* Instruction Styles */
.instruction-container {
    margin-bottom: 20px;
    width: 100%;
}

.instruction-button {
    width: 100%;
    background-color: white;
    color: #15213D;
    border: none;
    margin-top: 30px;
    border-bottom: 6px solid #15213D;
    margin-bottom: 20px;
    padding: 10px 0;
    font-size: 1.5em;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.instruction-button span {
    margin-left: 0;
}

.instruction-button .icon {
    margin-right: 10px;
}

.instruction-content {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    display: none;
    font-family: 'Poppins', sans-serif;
    color: #15213D;
}

.instruction-content.open {
    display: block;
    margin-top: 0;
}

.instruction-content h2 {
    color: #15213D;
    font-size: 1.4em;
    margin-bottom: 15px;
    border-bottom: 2px solid #15213D;
    padding-bottom: 10px;
}

.instruction-content h3 {
    color: #1E2E4D;
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 10px;
}

.instruction-content ul {
    padding-left: 20px;
}

.instruction-content li {
    margin-bottom: 8px;
}

.instruction-content ul ul {
    margin-top: 8px;
}

.instruction-content .color-dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
}

.instruction-content .blue-dot {
    background-color: rgba(21, 33, 61, 1);
}

.instruction-content .yellow-dot {
    background-color: #fdc32e;
}

/* Admin Buttons Styles */
.admin-buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.edit-content-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    transition: background-color 0.3s;
}

.edit-content-button:hover {
    background-color: #45a049;
}

/* Responsive Styles */
.tap-icon {
    margin-right: 10px;
    color: #e0e0e0;
    font-size: 24px;
}

.non-admin-info {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-top: 1px solid #e0e0e0;
    margin-top: 10px;
    font-size: 18px;
    color: #e0e0e0;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .content-wrapper {
        flex-direction: column;
    }

    .events-section,
    .calendar-section {
        width: 100%;
        max-width: 100%;
    }

    .calendar-section {
        order: -1;
        margin-bottom: 20px;
        margin-top: 0;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .calendar {
        font-size: 1em;
    }

    .calendar-day {
        aspect-ratio: 1 / 1;
    }

    .date-number {
        font-size: 1em;
    }

    .event-dot {
        width: 6px;
        height: 6px;
    }
}

@media screen and (max-width: 768px) {
    .calendar-section {
        max-width: 450px;
    }

    .instruction-button {
        font-size: 0.9em;
        padding: 8px 0;
    }

    .instruction-content {
        font-size: 0.9em;
        padding: 15px;
    }

    .instruction-content h2 {
        font-size: 1.3em;
    }

    .instruction-content h3 {
        font-size: 1.1em;
    }

    .calendar {
        font-size: 0.9em;
    }

    .date-number {
        font-size: 0.9em;
    }

    .event-dot {
        width: 5px;
        height: 5px;
    }

    .filters {
        flex-wrap: wrap;
    }

    .filters > * {
        flex: 1 0 calc(50% - 5px);
        margin-bottom: 10px;
    }

    .header-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .add-event-button {
        margin-top: 10px;
    }

    h3 {
        padding-bottom: 5px;
        padding-top: 5px;
    }

    .event-datetime {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        border-left: none;
        border-bottom: 2px solid rgb(255, 255, 255);
        padding-left: 0;
        padding-bottom: 10px;
    }

    .event-content > p {
        padding-top: 10px;
    }

    .event-datetime > div:first-child {
        margin-right: 10px;
    }

    .registration-data {
        flex-direction: column;
        align-items: flex-start;
    }

    .registration-data p {
        text-align: left;
    }

    .non-admin-info {
        font-size: 14px;
        white-space: nowrap;
        padding: 10px 15px;
        justify-content: center;
    }

    .tap-icon {
        font-size: 20px;
    }
}

@media screen and (max-width: 480px) {
    .calendar-section {
        max-width: 350px;
    }

    .instruction-button {
        font-size: 0.8em;
        padding: 6px 0;
    }

    h3 {
        padding-bottom: 5px;
        padding-top: 5px;
    }

    .instruction-content {
        font-size: 0.8em;
        font-family: 'Poppins', sans-serif;
        padding: 10px;
    }

    .instruction-content h2 {
        font-size: 1.2em;
    }

    .instruction-content h3 {
        font-size: 1em;
    }

    .calendar {
        font-size: 0.8em;
    }

    .date-number {
        font-size: 0.9em;
        top: 2px;
        left: 2px;
    }

    .event-dots {
        bottom: 2px;
        height: 45%;
    }

    .event-dot {
        width: 5px;
        height: 5px;
    }

    .non-admin-info {
        font-size: 13px;
        white-space: nowrap;
        padding: 10px 15px;
        justify-content: center;
    }

    .tap-icon {
        font-size: 18px;
    }

}

@media screen and (max-width: 390px) {
    .calendar-section {
        max-width: 300px;
    }

    .calendar {
        font-size: 0.7em;
    }

    .date-number {
        font-size: 1em;
    }

    .event-dots {
        width: 80%;
        left: 10%;
        right: 10%;
        bottom: 1px;
        height: 50%;
    }

    .event-dot {
        width: 6px;
        height: 6px;
    }

    .instruction-content {
        font-size: 0.75em;
        padding: 8px;
    }

    .instruction-content h2 {
        font-size: 1.1em;
    }

    .instruction-content h3 {
        font-size: 0.95em;
    }

    .non-admin-info {
        font-size: 12px;
        white-space: nowrap;
        padding: 10px 15px;
        justify-content: center;
    }

    .tap-icon {
        font-size: 16px;
    }
}

@media screen and (max-width: 360px) {
    .calendar-section {
        max-width: 280px;
    }

    .calendar {
        font-size: 0.65em;
    }

    .date-number {
        font-size: 1.1em;
    }

    .event-dots {
        gap: 1px;
    }

    .event-dot {
        width: 5px;
        height: 5px;
    }

    .instruction-content {
        font-size: 0.7em;
        padding: 6px;
    }

    .instruction-content h2 {
        font-size: 1em;
    }

    .instruction-content h3 {
        font-size: 0.9em;
    }

    .non-admin-info {
        font-size: 12px;
        white-space: nowrap;
        padding: 10px 15px;
        justify-content: center;
    }

    .tap-icon {
        font-size: 16px;
    }
}
