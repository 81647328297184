.contentMessage {
    background-color: #f9f9f9; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    padding: 40px; 
    z-index: 1000;
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translateX(-50%); 
    width: 80%; 
    max-width: 600px; 
    margin: auto; 
    box-sizing: border-box; 
    font-family:'Poppins', sans-serif;
}
.confirmed{
    text-align: center;
    font-size: 25px;
    /* color: rgb(48, 212, 43); */
    font-weight: bold;
    margin-bottom: 8px;
    border-bottom: 2px solid black;
    word-spacing: 2px;
}
.notConfirmed{
    font-size: 25px;
    text-align: center;
    /* color: red; */
    margin-bottom: 5px;
    border-bottom: 2px solid black;
    font-weight: bold;
    word-spacing: 2px;
}
.notFound{
    font-size: 25px;
    text-align: center;
    color: red;
    margin-bottom: 5px;
 
}
.wydzial{
    font-weight: 550;
}

.bodystat{
margin-top: 10px;
}
.line{
    margin-top: 10px;
    border-bottom: 2px solid black;
}
/* ===============================================UserRegistrationCancel===============================================*/

.contentMessageCancel{
    background-color: #f9f9f9; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    padding: 40px; 
    z-index: 1000;
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translateX(-50%); 
    width: 80%; 
    max-width: 600px; 
    margin: auto; 
    box-sizing: border-box; 
  
}
.messageCancel{
    font-family:'Poppins', sans-serif;
    font-size: 1.50em;
    text-align: center;
    color: #333;
    text-decoration:wavy;
    font-weight: bold;
   
}
.topline{
    border-bottom: 2px solid black;
    margin-bottom: 10px;
}
.bottline{
    border-bottom: 2px solid black;
    margin-top: 10px;
}

/* ===============================================UserRegistrationConfirm===============================================*/
.message, .messageStatus, .status {
    color: #333; 
    font-size: 1.2em; 
    margin-bottom: 15px; 
    word-spacing: 2px;
}
.message {
    
}
.status {
    text-align: center; 
    font-weight: bold; 
    font-size: 25px;
    border-bottom: 2px solid black;
}
.space {
    height: 30px; 
}
.wydzialConf{
    font-weight: 550;
    
}
/* ===============================================UserRegistrationcheck===============================================*/


.registrationCheckContent {
    text-align: center;
}

.registrationCheckContent h2 {
    font-size: 25px;
    color: #333;
    margin-bottom: 20px;
    border-bottom: 6px solid #1a2b4f;
    padding-bottom: 15px;
}

.emailInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none
}
.emailInput:focus{
    border: 1px solid #9ec7f3;
    box-shadow: 0 0 5px #9ec7f3;
    outline: none;
}
.checkButton {
    background-color: rgb(17, 30, 43);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.checkButton:hover {
    background-color: rgb(14, 28, 43);
}
.registrationInfoText{
    text-align: center;
    color: grey;
}

.registrationInfo {
    margin-top: 20px;
    text-align: left;
}

.registrationInfo h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
    border-bottom: 2.4px solid #1a2b4f;
    padding-bottom: 5px;
}

.registrationInfo p {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
    margin-top: 15px;
}

.topLine, .bottomLine {
    border-bottom: 2px solid black;
    margin: 10px 0;
}

/* ===============================================UserRegistrationInfo===============================================*/

.registrationCheckContainer {
    margin-top: 30px;
    position:relative;
    padding: 40px;
    z-index: 1000;
    margin:0 auto;
    max-width: 600px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    min-height: 30vh;
    max-height: 80vh;
    overflow-y: auto;
    margin-top:100px;
}
@media only screen and (max-width: 400px)
{

    .registrationCheckContainer {

        padding: 10px !important;
        width: -webkit-fill-available;
        border-radius: 2px !important;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
        overflow-y: auto;
    }
}

.eventItem, .registrationItem {
    margin-bottom: 10px;
}

.eventToggle {
    width: 100%;
    text-align: left;
    padding: 10px;
    /* background-color: #bfc0c0; */
    /* background-color: #9ec7f3; */
    background-color: rgba(21, 33, 61, 1);
    color: #ffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}
.eventToggleEmpty{
    width: 100%;
    text-align: left;
    padding: 10px;
    /* background-color: #bfc0c0; */
    background-color: #fdc32e !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}
.registrationToggle{
    width: 100%;
    text-align: left;
    padding: 10px;
    /* background-color: #e2e4e4; */
    background-color: rgba(21, 33, 61, 1);
    color: #ffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    
}

.eventToggle:hover  {
    
    /* background-color: rgb(155, 156, 156); */
    background-color: #1a2b4f;
}
.registrationToggle:hover{
    /* background-color: rgb(155, 156, 156); */
    /* background-color: #bfc0c0; */
    background-color: #1a2b4f;
}
.registrationToggleActive{
    /* background-color: #bfc0c0; */
    background-color: #1a2b4f;
}

.registrationsList {
    margin-left: 20px;
    margin-top: 10px;

}

.registrationDetails {
    margin-left: 20px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
}

.topLine, .bottomLine {
    border-top: 1px solid #dee2e6;
    margin: 20px 0;
}
.registrationDetailsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #ffff
}

.registrationText  {
    max-width: 75%; /* Ogranicza do połowy szerokości */
    white-space: normal; /* Pozwala na zawijanie tekstu */
    word-wrap: break-word; /* Zawija długie słowa */
    color: #ffff
}

.registrationText p  {

    color: #ffff;
    font-size: 16px;
}
.registrationStatus .fa-check,
.registrationStatus .fa-xmark {
    margin-left: 5px; /* Dystans między tekstem a ikoną */
}
.confirmButton {
    margin-top: 8px;
    padding: 6px 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.confirmButton:hover {
    background-color: #45a049;
}

.confirmButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.registrationStatus {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.registrationStatus.unconfirmed {
    color: #f44336;
}
.successMessage {
    margin: 20px 0;
    padding: 15px;
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
    color: #3c763d;
    border-radius: 4px;
    text-align: center;
}

.errorMessage {
    margin: 20px 0;
    padding: 15px;
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    color: #a94442;
    border-radius: 4px;
    text-align: center;
}

.emailInput:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.checkButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}