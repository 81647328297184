.popup-content-reminder{
    position:relative;
    background-color: #d9d9d9;
    padding: 20px;
    border-radius: 10px;
    width: auto;
    max-width: 615px;
    height: auto;
    max-height: 100vh;
    box-sizing: border-box;
}


.passwordReminderForm{
    width: 45vh; 
   height: auto;
   box-sizing: border-box;
}

.passwordReminderForm h2 {
    font-size: 28px; 
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
}
.Email-error {
    color: red; 
    text-align: center;
    margin-bottom: 20px; 
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 540;
}
.Email-success{
    color: green;
    text-align: center;
    margin-bottom: 20px; 
}

.passwordReminderForm .input-box {
    position: relative;
    margin-bottom: 10px;
    margin: 20px 0;
    padding-bottom: 10px;

}

 .input-box input[type="text"] {
    width: 100%;
    padding: 10px 20px 10px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.passwordReminderForm .input-box input:focus{
    border: 1px solid #9ec7f3;
    box-shadow: 0 0 5px #9ec7f3;
    outline: none;
}


.passwordReminderForm .reset-instruction {
    padding-left: 20%;
    padding-right: 20%;
    margin-bottom: 10px;
    text-align: center;
}
.reset-instruction .reset{
    display: block;
    font-size: 12px;
    color: #707070;
    border-radius: 25px; 
    font-family:Arial, Helvetica, sans-serif;
 
}

.passwordReminderForm .btn {
    width: 75%;
    height: 30.5px;
    border: none;
    outline: none;
    background-color: rgb(17, 30, 43);
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    color: whitesmoke;
   display: block;
   margin: auto;
   margin-bottom: 10px;
}

.passwordReminderForm .btnBack {
    width: 50%;
    height: 30.5px;
    border: none;
    outline: none;
    background-color: rgb(17, 30, 43);
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    color: whitesmoke;
    display: block;
    margin: auto;
    margin-top: 10px;
}

/* .popup-content{
    background-color: #d9d9d9;
    padding: 20px;
    border-radius: 10px;
    width: auto;
    max-width: 500px;
    height: auto;
    max-height: 100vh;
    box-sizing: border-box;
} */
