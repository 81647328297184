/* notification.style.css */
.notification-container {
    max-width: 800px;
    margin: 0 auto;
}

.notification-display {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 1em;
    line-height: 1.5;
    color: #333;
}

.notification-display ul,
.notification-display ol {
    padding-left: 1.5em;
    margin-left: 0;
}

.notification-display li {
    padding-left: 0.5em;
}

.notification-editor {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
}

.notification-editor .quill {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    min-width: 300px;
    width: 100%;
    box-sizing: border-box;
}

/* Quill specific styles */
.notification-editor .ql-container {
    font-family: inherit;
    font-size: 16px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    min-height: 150px;
}

.notification-editor .ql-editor {
    padding: 12px 15px;
    min-height: 150px;
}

.notification-editor .ql-editor ul,
.notification-editor .ql-editor ol {
    padding-left: 2.5em;    /* Increased padding */
    margin-left: 1em;       /* Added margin */
}

.notification-editor .ql-editor li {
    padding-left: 1em;      /* Increased padding */
    margin: 0.5em 0;        /* Added vertical spacing between list items */
}

/* Nested list styling */
.notification-editor .ql-editor li > ol,
.notification-editor .ql-editor li > ul {
    margin-left: 1em;       /* Indent nested lists */
    padding-left: 2em;
}

.notification-editor .ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #f8f8f8;
}

.edit-notification-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;
}

.edit-notification-button:hover {
    background-color: #45a049;
}

.editor-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
}

.save-button,
.cancel-button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition-duration: 0.3s;
}

.save-button {
    background-color: #4CAF50;
    color: white;
}

.save-button:hover {
    background-color: #45a049;
}

.cancel-button {
    background-color: #f44336;
    color: white;
}

.cancel-button:hover {
    background-color: #d32f2f;
}

/* Responsive styles */
@media (max-width: 768px) {
    .notification-display,
    .notification-editor .ql-editor {
        font-size: 0.9em;
    }

    .edit-notification-button,
    .save-button,
    .cancel-button {
        font-size: 14px;
        padding: 8px 12px;
    }

    .notification-editor {
        padding: 10px;
    }
}