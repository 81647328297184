.background-image-manager {
    width: 80%;
    max-width: 800px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    max-height: 70vh;
    overflow-y: auto;
    margin-top: 8vh;
}

.user-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
}

.user-list-header h2 {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
}

.header-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
}

.add-event-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-event-button:hover {
    background-color: #45a049;
}

.user-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    margin-top: 20px;
}

.user-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.3s;
}

.user-item:hover {
    transform: scale(1.02);
}

.user-item span {
    color: #333;
    font-size: 1em;
    word-break: break-word;
    margin-right: 10px;
}

.delete-icon {
    background: none;
    border: none;
    color: #f44336;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    transition: color 0.3s;
    flex-shrink: 0;
}

.delete-icon:hover {
    color: #d32f2f;
}

.upload-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
}

.upload-modal h2 {
    margin: 0 0 20px 0;
    color: #333;
    font-size: 1.5em;
}

.form-group {
    margin-bottom: 15px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
}

.form-group input:focus {
    outline: none;
    border-color: #4CAF50;
}

.error-text {
    color: #f44336;
    font-size: 0.8em;
    margin-top: 5px;
}

.upload-modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.upload-modal-buttons button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.close-button {
    background: none;
    border: none;
    color: #f44336;
    cursor: pointer;
    transition: color 0.3s;
}

.close-button:hover {
    color: #d32f2f;
}

.upload-button {
    background-color: #4CAF50;
    color: white;
    border: none;
}

.upload-button:hover {
    background-color: #45a049;
}

.error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}

.loading {
    text-align: center;
    padding: 20px;
    color: #666;
}

@media (max-width: 768px) {
    .background-image-manager {
        width: 95%;
        padding: 15px;
    }

    .user-grid {
        grid-template-columns: 1fr;
    }

    .user-list-header {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .header-buttons {
        width: 100%;
        justify-content: space-between;
    }

    .upload-modal-buttons {
        flex-direction: column;
    }

    .upload-modal-buttons button {
        width: 100%;
        justify-content: center;
    }
}