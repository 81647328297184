/* EditPopup Styles */
body.popup-open {
    overflow: hidden;
}

.navbar.disabled {
    pointer-events: none;
    opacity: 0;
    display: none !important;
    transition: opacity 0.3s ease;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.popupContent {
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 1000px;
    height: auto;
    max-height: 90vh;
    box-sizing: border-box;
    margin: auto;
    overflow-y: auto;
    background-color: #f5f5f5;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2001;
}

.edit-error {
    color: #d32f2f;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    background-color: #ffcdd2;
    border-radius: 4px;
    font-weight: 500;
    font-family: Arial, sans-serif;
}

.editForm {
    width: 100%;
    margin: auto;
    height: auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.headerContainerr {
    width: 100%;
    margin: auto;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.contentBelowLine {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
}

.headerContainerr > h2 {
    font-size: 28px;
    text-align: center;
    font-family: Arial, sans-serif;
    margin: 0 0 10px 0;
    color: #333;
}

.checkboxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.checkboxContainer .checkboxVerif {
    transform: scale(1.5);
    margin: 0 10px;
}

.textVisibility {
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textVisibility a {
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
    font-family: 'Arial', sans-serif;
}

.editForm label {
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    display: block;
    margin-bottom: 5px;
}

.editForm .inputBox {
    width: 100%;
    margin-bottom: 20px;
}

.editForm .inputBox input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.editForm .inputBox input[type="text"]:focus {
    border-color: #2196f3;
    outline: none;
}

.editForm .inputEdit {
    margin-bottom: 20px;
}

.editForm .inputEdit .quill {
    height: 300px;
    margin-bottom: 20px;
    border-radius: 4px;
    overflow: hidden;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #ccc;
    border-bottom: none;
}

.ql-container.ql-snow {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #ccc;
    border-top: none;
}

.editForm .btn {
    width: 50%;
    padding: 12px;
    border: none;
    outline: none;
    background-color: #2196f3;
    color: white;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 20px auto 0;
    transition: background-color 0.3s ease;
}
.editForm .btn:hover {
    background-color: #1976d2;
}
.close-button {
    position: absolute;
    top: 25px;
    right: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 25px;
    z-index: 1600;
    color: #000000; /* Change to your desired color */
}

/* Quill editor custom styles for standard fonts */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
    content: 'Arial';
    font-family: Arial, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Times New Roman"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Times New Roman"]::before {
    content: 'Times New Roman';
    font-family: 'Times New Roman', serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Courier New"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Courier New"]::before {
    content: 'Courier New';
    font-family: 'Courier New', monospace;
}

.ql-font-Arial {
    font-family: Arial, sans-serif;
}

.ql-font-Times-New-Roman {
    font-family: 'Times New Roman', serif;
}

.ql-font-Courier-New {
    font-family: 'Courier New', monospace;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .popupContent {
        width: 95%;
        padding: 15px;
    }

    .editForm {
        padding: 20px;
    }

    .headerContainerr > h2 {
        font-size: 24px;
    }

    .editForm .btn {
        width: 100%;
    }

    .editForm .inputEdit .quill {
        height: 200px;
    }
}