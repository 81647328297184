/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Montserrat:wght@300;400;700&family=Open+Sans:wght@300;400;700&family=Lato:wght@300;400;700&display=swap');

/* Quill font classes */
.ql-font-Times-New-Roman {
    font-family: 'Times New Roman', Times, serif !important;
}

.ql-font-Roboto-Condensed {
    font-family: 'Roboto Condensed', sans-serif !important;
}

.ql-font-Montserrat {
    font-family: 'Montserrat', sans-serif !important;
}

.ql-font-Open-Sans {
    font-family: 'Open Sans', sans-serif !important;
}

.ql-font-Lato {
    font-family: 'Lato', sans-serif !important;
}

/* Quill font picker customization */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Times New Roman"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Times New Roman"]::before {
    content: 'Times New Roman';
    font-family: 'Times New Roman', Times, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Roboto Condensed"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Roboto Condensed"]::before {
    content: 'Roboto Condensed';
    font-family: 'Roboto Condensed', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
    content: 'Montserrat';
    font-family: 'Montserrat', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Open Sans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Open Sans"]::before {
    content: 'Open Sans';
    font-family: 'Open Sans', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
    content: 'Lato';
    font-family: 'Lato', sans-serif;
}