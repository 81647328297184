.groupRegistrationContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(244, 244, 244);
    color: #000;
    width: 60%;
    padding: 40px;
    border-radius: 10px;
    z-index: 9999;
    max-height: 90vh;
    overflow: hidden;
}

.groupRegistrationBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

.groupRegistrationTitle {
    text-align: center;
    border-bottom: 6px solid rgba(21, 33, 61, 1);
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.groupRegistrationContent {
    width: 80%;
    margin: auto;
    height: calc(100vh - 200px);
    text-align: center;
    overflow-y: auto;
}

.groupRegistrationContent::-webkit-scrollbar {
    width: 12px;
}

.groupRegistrationContent::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
}

.groupRegistrationContent::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.groupRegistrationActions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.groupRegistrationActions>button {
    border-radius: 10px;
    padding: 20px;
    font-size: 20px;
    color: #fff;
}

/* Event Element Confirm Styles */
.eventElementConfirm {
    background-color: rgba(21, 33, 61, 1);
    color: #fff;
    min-height: 130px;
    display: flex;
    border-radius: 5px;
    position: relative;
    margin: 6px;
    padding: 10px;
    gap: 10px;
}

.eventElementConfirm>.confirmationStatus {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px 0 40px 0;
    height: 40px;
    width: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eventElementConfirm>.leftSide {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 30px;
    gap: 8px;
}

.eventElementConfirm>.rightSide {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 0;
    gap: 8px;
}

.room-info {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #ffb800;
}

.date-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}

.date-info p {
    margin: 0;
}

.eventElementConfirm>.rightSide>.btn {
    color: rgba(21, 33, 61, 1);
    background-color: rgba(217, 217, 217, 1);
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
    padding: 6px 12px;
    border: none;
    cursor: pointer;
    min-width: 90px;
    margin-top: 5px;
}

.error {
    color: #EF4444;
    margin-top: 8px;
    text-align: center;
}

.success {
    color: #10B981;
    margin-top: 8px;
    text-align: center;
}

/* Hide navbar when modal is open */
body:has(.groupRegistrationBackdrop) .Navbar {
    display: none !important;
}

@media screen and (max-width: 400px) {
    .groupRegistrationContainer {
        width: 95%;
        padding: 15px;
    }

    .groupRegistrationContent {
        width: 100%;
    }

    .eventElementConfirm {
        min-height: auto;
        margin: 5px;
        padding: 10px;
        padding-top: 45px;
    }

    .eventElementConfirm>.leftSide {
        padding-top: 0;
    }

    .eventElementConfirm>.leftSide svg {
        width: 45px !important;
        height: 45px !important;
    }
}