/*////////////////////////////////////////////////////////EventElementContainer////////////////////////////////////////////////////////////*/

.informationType{
    background-color: #fdc32e;
    height: 360px;
    display: flex;
    border-radius: 5px;
    position: relative;
}

.eventElementContainer {
    background-color: rgba(21, 33, 61, 1);
    color: #fff;
    height: 360px;
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    border-radius: 5px;
    position: relative;
    /* padding: 15px;
    margin-top: 20px; */
}

.bottomText {
    position: absolute;
    bottom: 0;
    left: 5%;
    right: 5%;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #ffffff;
    box-sizing: border-box;
    background-color: rgba(21, 33, 61, 1);
    margin: 0;
}


.eventElementContainer>.visionButton {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgb(79 131 185);
    border-radius: 5px 0 40px 0;
    height: 50px;
    width: 50px;
    border: none;
}
.eventElementContainer>.listButton{
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #6ED272;
    border-radius: 40px 0 0px 0;
    height: 50px;
    width: 50px;
    border: none;

}
.eventElementContainer>.listButton svg{
    margin-top: 5px;
    margin-left: 5px;
}

.eventElementContainer>.deleteButton {
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgb(201 97 97);
    border-radius: 0 5px 0 40px;
    height: 50px;
    width: 50px;
    border: none;
}
.eventElementContainer > .importantButton {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #FFD700; /* Consistent yellow background */
    border-radius: 0 40px 0 5px;
    height: 50px;
    width: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.eventElementContainer > .importantButton svg {
    width: 20px;
    height: 20px;
    color: #888; /* Gray for inactive state */
    transition: color 0.3s ease;
}

.eventElementContainer > .importantButton.active svg {
    color: #FFFFFF; /* White color when active */
}

.warning-message {margin-bottom: 35vw !important;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.8); /* Semi-transparent red */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    z-index: 1000;
    animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    10%, 90% { opacity: 1; }
}
/* Warning popup styles remain the same */
.leftSide,
.rightSide{
    width: 50%;
    text-align: center;
}

.leftSide{
    padding: 60px 40px 80px 60px;
}

.rightSide{
    padding: 60px 60px 80px 40px;
}

.rightSide>* {
    margin: 10px;
}

.leftSide>* {
    margin: 8px;

}
.leftSide img{
    margin-left: 15px;
    width: 110px;
    margin-top: 1px;
}

.buttonEvent {
    color: rgba(21, 33, 61, 1);
    background-color: rgba(200, 200, 200, 1);
    text-align: center;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 8px 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    border: none;
    cursor: pointer;
    margin: 5px;
}

.buttonEvent:hover {
    background-color: rgba(180, 180, 180, 1);
    transform: translateY(-2px);
}

.buttonEvent:active {
    transform: translateY(0);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.informationType .rightSide {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(21, 33, 61, 1);
}

.informationType .leftSide {
    color: rgba(21, 33, 61, 1);
}

.informationType .bottomText {
    border-top: 1px solid rgba(21, 33, 61, 1);
    color: rgba(21, 33, 61, 1);
    box-sizing: border-box;
    background-color: #fdc32e;

}
.centeredTime {
    text-align: center;
}

/* Ensure the time text is centered within its container */
.centeredTime p {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
}
@media screen and (max-width: 1200px) {
    .departmentsFilter {
        grid-template-columns: 1fr 1fr 1fr !important;

        margin-bottom: 25vw !important;
    }
    .eventElementContainer {
        margin: 1vw auto 1vw auto !important;
        width: 100%;
        justify-content: center;
    }
}
@media screen and (max-width: 1000px) {
    .departmentsFilter {
        grid-template-columns: 1fr 1fr 1fr !important;

        margin-bottom: 35vw !important;
    }
    .eventElementContainer {
        margin: 1vw auto 1vw auto !important;
        width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 800px) {
    .leftSide {
        /* padding: 60px; */

        padding: 8vh 2vw 8vh 4vw;
    }

    .rightSide {    padding: 8vh 4vw 8vh 2vw;}
    .eventElementContainer {
        /* padding: 10px; */
        font-size: 17px;
    }
    .input input {margin-left:0px;}
    .buttonEvent{width: 90%;
        min-width: fit-content;}

}

@media screen and (max-width: 400px) {


    .rightSide {
        padding: 90px 5px 80px 5px;
    }
    .leftSide {
        padding: 70px 10px 80px 5px;
    }
    /* .leftSide .h5{
        padding: 90vw 5vw 5vw 5vw;
    } */

    .eventElementContainer {
        font-size: 4.5vw;
    }

}

/*////////////////////////////////////////////////////////EventElementsList////////////////////////////////////////////////////////////*/

.eventElementsList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    justify-content: center;
}

.nextPages {
    margin: 40px;
    text-align: center;
}

.nextPages>button {
    font-size: 18px;
    margin: 10px;
    border: none;
    background: none;
}


.sortEventElements {
    display: flex;
    margin: 20px;
}

.sortEventElements select {
    font-size: 20px;
    margin-left: 20px;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 20px;
}

@media screen and (max-width: 1000px) {
    .eventElementsList {
        grid-template-columns: 1fr;
        margin-left: 10px;
        margin-right: 10px;
    }
    .sortEventElements select {    width: -webkit-fill-available;}

    .sortEventElements>h3 {
        display: none;

    }

    .sortEventElements select {
        font-size: 14px;
        margin-left: 0;
    }
    .departmentsFilter{
        padding: 10px;
        gap: 1.5vw;
    }
}

/*////////////////////////////////////////////////////////EventElementPopup////////////////////////////////////////////////////////////*/

.popupEventElementContainer {
    background-color: rgba(21, 33, 61, 1);
    color: #fff;
    min-height: 260px;
    width: 60%;
    border-radius: 10px;
}


.leftSidePopup,
.rightSidePopup {
    width: 50%;
    padding: 30px;
    text-align: center;
}

.leftSidePopup {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.rightSidePopup {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    overflow-wrap: break-word;
}

@media screen and (max-width: 800px) {
    .popupContainer {
        width: 100%;
    }

    .rightSidePopup> {
        font-size: 12px;
    }

    .popupEventElementContainer{
        width: 98%;
        font-size: 17px;
    }
    .popupNavbar{padding-bottom: 17px;}

    .leftSidePopup, .rightSidePopup {
        padding:5%;}

    .input  input {margin-left:0 !important;}
    .popupRegistrationContainer span{
        font-weight: bolder;
        font-size: larger;
        display:block;
        width: fit-content;
        margin: 0 auto;
    }
}


.popupNavbar {
    width: 90%;
    /* height: 60px; */
    margin: auto;
    border-bottom: 5px solid #fff;
    padding-top: 17px;
}

.popupNavbar>h2 {
    margin-top: 20px;
}


/*////////////////////////////////////////////////////////DepartmentsContainer////////////////////////////////////////////////////////////*/


.departmentsFilter {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.5vw;
    padding: 10px;
}

.departmentImage {
    background-color: rgba(240, 240, 240, 1);
    text-align: center;
    height: 120px;
    padding-top: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: rgb(0, 5, 0) 0px 0px 10px;
}

.departmentName {
    background-color: rgba(21, 33, 61, 1);
    color: #fff;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    height: 100px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.departmentName h4{
    font-size: 17px;
}
.department-show-all-events-container {
    grid-column: 1 / -1;
    margin-bottom: 20px;
}

.department-show-all-events-button {
    height: 50px !important;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.department-show-all-events-button h4 {
    margin: 0;
    font-size: 18px;
}
@media screen and (max-width: 800px) {
    .departmentsFilter {
        grid-template-columns: 1fr 1fr !important;
        height: 740px;
        margin-bottom: 80px;
        padding:10px;
        gap: 15px;
    }
    .sortEventElements select {    width: -webkit-fill-available;}

    .bottomText {font-size: medium;}
}

/*////////////////////////////////////////////////////////RegistrationPopup////////////////////////////////////////////////////////////*/

.popupRegistrationContainer {
    background-color: rgba(244, 244, 244, 1);
    color: #000;
    width: 40%;
    padding: 60px;
    border-radius: 10px;
}

.titleRegisPopup {
    text-align: center;
    border-bottom: 6px solid rgba(21, 33, 61, 1);
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.contentRegisPopup {
    margin: 10px;
    height: 560px;
}


.input input{
    width: -webkit-fill-available;
    height: 100%;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 10px;
    padding: 20px 45px 20px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 22.5px;
    margin: 10px;

}

.buttonRegis {
    color: rgba(244, 244, 244, 1);
    background-color: rgba(45, 71, 132, 1);
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: block;
    margin: 0 auto;
}

@media screen and (max-width: 900px) {
    .popupRegistrationContainer {
        width: 98%;
        padding: 10px;
    }
    .contentRegisPopup {
        width: 100%;
        /* height: 630px; */
        height: fit-content !important;
        margin-top:20px;
        margin-bottom: 20px;
    }
    .buttonRegis {width: 50%;
        margin-top: 10px;}

}
@media screen and (min-width: 800px) {
    .content{min-width: 790px;}
    .main{min-width: 790px;}
    .leftSide {
        padding-left:3vw;
        padding-right: 1vw;
        padding-top: 8vh;
        padding-bottom: 2vh;

    }
    .rightSide {
        padding-left:1vw;
        padding-right: 3vw;
        padding-top: 8vh;
        padding-bottom: 2vh;

    }
    .eventElementContainer{min-height: 360px;max-height: auto;}
    .buttonEvent{width: 90%;}
    .popupNavbar{padding-bottom: 17px;}
    .popupRegistrationContainer{min-width: 750px;
        width: auto !important;
        padding:30px;
    }
    .popupRegistrationContainer  span{
        display: block;
        width: fit-content;
        margin: 0 auto;
        font-weight: bold;
        font-size: larger;
        margin:0 auto;
    }
    .input input {
        padding: 10px 25px 10px 10px;
    }
    .buttonRegis{min-width: 150px;}
    .departmentsFilter {gap:1vw !important}

}
@media screen and (max-width: 400px) {
    .eventElementsList {
        grid-template-columns: 1fr;
        margin-left: 5px;
        margin-right: 5px;
    }
}
@media screen and (max-width: 400px) {
    .textContainer{ padding-left: 0 !important;}
    .main {
        width: 98%;
    }
    .description {
        margin-left: 0px;
        margin-right: 0px ;}
    .departmentsFilter {
        padding:0px !important;
        gap: 1vw;
    }
    .departmentsFilter {
        /* padding: 10px; */
        gap: 1.5vw;
    }
    .popupRegistrationContainer {
        width: 98%;
        padding: 10px;
        max-height: 92vh;
    }
    .titleRegisPopup {
        border-bottom: 3px solid rgba(21, 33, 61, 1);
        margin-bottom: 0px;
        font-size: 12px;
    }

    .popupRegistrationContainer span {
        font-weight: bolder;
        font-size: medium;
        display: block;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        margin-top: 5px;
    }
    .input input {
        padding:10px 25px 10px 10px;}
    .contentRegisPopup{margin: 0px}
    .popupRegistrationContainer{
        font-size:12px !important;
        margin:8px;
    }
    .content{
        font-size: smaller !important;
    }
    .departmentName h4 {
        font-size: 14px;
    }
    .leftSide, .rightSide {
        padding: 8vh 2vw 8vh 4vw;
        font-size: smaller;
    }
    .popupEventElementContainer{
        font-size: 14px;
    }
    .leftSidePopup, .rightSidePopup {
        padding: 3%;
    }
    .rightSidePopup span{
        font-size:14px !important;
    }
    .buttonEvent { font-size:14px !important}
    .contentRegisPopup input[type="checkbox"]{
        width: 15px !important;
        height: 15px !important;
    }
    .contentRegisPopup label {
        font-size:14px !important;
    }
    .contentRegisPopup{
        height: fit-content !important;
    }
    @media screen and (max-width: 900px) {
        .buttonRegis {
            margin-top: 4px !important;
        }
    }
}



/*////////////////////////////////////////////////////////DeleteElementPopup////////////////////////////////////////////////////////////*/

.deleteElementContainer {
    background-color: rgba(244, 244, 244, 1);
    color: #000;
    width: 40%;
    padding: 40px;
    border-radius: 10px;
}


.titleDeleteElementPopup {
    text-align: center;
    border-bottom: 6px solid rgba(21, 33, 61, 1);
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.contentDeleteElementPopup {
    width: 80%;
    margin: auto;
    height: 100px;
    text-align: center;
}

.deletePopupActions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.deletePopupActions>button {
    border-color: #fff;
    color: #fff;
    border-radius: 10px;
    height: 40px;
    font-size: 16px;
}

/*////////////////////////////////////////////////////////EditElementPopup////////////////////////////////////////////////////////////*/
.popupEditElementContainer {
    background-color: rgba(244, 244, 244, 1);
    color: #000;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    border-radius: 10px;
    max-height: 90vh;
    overflow-y: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.titleEditElement {
    text-align: center;
    border-bottom: 6px solid rgba(21, 33, 61, 1);
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.titleEditElement h1 {
    margin: 0;
    font-size: 24px;
}

.contentEditElement {
    width: 100%;
}

.inputEdit {
    margin-bottom: 15px;
}

.inputEdit label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.inputEdit input[type="text"],
.inputEdit select,
.inputEdit textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid rgba(200, 200, 200, 0.2);
    border-radius: 5px;
    font-size: 14px;
    background-color: #fff;
}

.inputEdit textarea {
    height: 100px;
    resize: vertical;
}

.buttonSave {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: rgba(0, 166, 56, 1);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.buttonSave:hover {
    background-color: rgba(0, 140, 47, 1);
}

.error {
    color: #ff0000;
    margin-top: 10px;
    text-align: center;
}

.succes {
    color: #00a638;
    margin-top: 10px;
    text-align: center;
}

@media (max-width: 768px) {
    .popupEditElementContainer {
        width: 95%;
        padding: 15px;
    }

    .titleEditElement h1 {
        font-size: 20px;
    }

    .inputEdit input[type="text"],
    .inputEdit select,
    .inputEdit textarea {
        font-size: 16px;
    }
}


/*////////////////////////////////////////////////////////CreateEventElement////////////////////////////////////////////////////////////*/

.createElementContainer {
    background-color: #6ED272;
    color: #fff;
    min-height: 300px;
    border-radius: 5px;
    text-align: center;
    padding: 80px;
}

.createElementContainer > button {
    background-color: rgba(217, 217, 217, 1);
    color:  #4bb14f;
    padding: 10px;
    border-radius: 5px;
    font-size: 20px;
    width: 170px;
    height: 46px;
    font-weight: 550;
    border: none;
}

/*////////////////////////////////////////////////////////Others////////////////////////////////////////////////////////////*/

.error {
    color: red;
    margin-top: 10px;
}

.succes {
    color: green;
    margin-top: 10px;
}


/*////////////////////////////////////////////////////////ListElementPopup////////////////////////////////////////////////////////////*/

.eventElementListContainer {
    background-color: rgba(21, 33, 61, 1);
    height: auto;
    width: 100%;
    color: #fff;
    padding: 1em;
    border-radius: 5px;
    flex-direction: column;
}
.contentList{
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
}
.contentList::-webkit-scrollbar {
    width: 10px;
}

.contentList::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
    border: 3px solid rgba(21, 33, 61, 1);
}

@media (max-width: 1500px) {
    .eventElementListContainer {
        padding: 0.5em;

    }
    .textContainerList, .textItemsList {
        padding: 5px;
    }
    .textItemsList p{
        font-size: 14px;
    }
    .textItemsList.email p{
        font-size: 13px;
    }

}
@media (max-width: 1200px) {

    .textItemsList p{
        font-size: 12px;
    }
    .textItemsList.email p{
        font-size: 11px;
    }
    .dataHeadList, .timeHeadList {
        font-size: 30px;
    }

}

@media (max-width: 900px) {

    .textItemsList p{
        font-size: 10px;
    }
    .textItemsList.email p{
        font-size: 8px;
    }

}
@media (max-width: 600px) {
    .textItemsList p {
        font-size: 8px;
    }
    .textItemsList.email p{
        inline-size: 65px;
        overflow-wrap: break-word;
    }
}

.headerName {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}
.dataHeadList{
    text-align: center;
    font-size: 20px;

}
.timeHeadList{
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.facultyheadList {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}

.textContainerList {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
}

.textItemsList {
    margin: 0 10px;
    flex: 1;
    text-align: center;
}

.infoL {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: rgba(21, 33, 61, 1);
}
.errorList{
    font-size: 15px;
    text-align: center;

}

/*////////////////////////////////////////////////////////DeleteUserPopup////////////////////////////////////////////////////////////*/

.deleteUserEvent{
    background-color: rgba(244, 244, 244, 1);
    color: #000;
    width: 40%;
    padding: 40px;
    border-radius: 10px;
}
.titleDeleteUserEvent{
    text-align: center;
    border-bottom: 6px solid rgba(21, 33, 61, 1);
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.contentDeleteUserEvent{
    width: 80%;
    margin: auto;
    height: 100px;
    text-align: center;
}
.deleteUserActions{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 15px;
}
.deleteUserActions>button{
    border-color: #fff;
    color: #fff;
    border-radius: 10px;
    height: 40px;
    font-size: 16px;
}

@media (max-width: 860px) {
    .contentDeleteUserEvent h3 {
        font-size: 15px;
    }
    .deleteUserEvent{
        width: 50%;
    }
    .titleDeleteUserEvent h2{
        font-size: 20px;
    }
}
@media (max-width: 670px) {
    .contentDeleteUserEvent h3 {
        font-size: 13px;
    }
    .deleteUserEvent{
        width: 60%;
    }
    .titleDeleteUserEvent h2{
        font-size: 17px;
    }
}
@media (max-width: 400px) {
    .contentDeleteUserEvent h3 {
        font-size: 12px;
    }
    .deleteUserEvent{
        width: 75%;
    }
    .titleDeleteUserEvent h2{
        font-size: 15px;
    }
}
.error{
    font-weight: 550;
    color: red;
    text-align: center;
}